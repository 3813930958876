import { render, staticRenderFns } from "./UserClasses.vue?vue&type=template&id=3ac265be&scoped=true"
import script from "./js/user-classes.js?vue&type=script&lang=js&external"
export * from "./js/user-classes.js?vue&type=script&lang=js&external"
import style0 from "./UserClasses.vue?vue&type=style&index=0&id=3ac265be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ac265be",
  null
  
)

export default component.exports